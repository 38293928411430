import React, { useState, useMemo } from 'react';
import { Button, Div, Text } from '../shared/components';
import { ProductSection } from './product-section';
import { css } from '@emotion/css';
import { CustomDetailSectionWrapper } from './field-components/custom-detail-section-wrapper';
import { flex, px } from '../shared/shared-styles';
import { OptionalDetails } from './field-components/optional-details';
import { MultiNetwork } from './multi-network';

import { IncludedPrograms } from './included-programs';
import { SpendingAccounts } from './spending-accounts';
import { ProductDevSettings } from './product-dev-settings';
import { getProductNeedsAttention, productTypeConfigs } from './configs/form.config';
import { useStore } from '../store-provider/use-store';
import { Cpu } from 'phosphor-react';
import { ConditionPercentages } from './condition-percentages';
import { PRODUCT_HEADERS } from '../benefit-package/constants';

const productsWithBuyups = Object.keys(PRODUCT_HEADERS);
export const ProductForm = ({ state, openSections = false }) => {
  const {
    data: { devMode },
  } = useStore();
  const { product, layout, network } = state;

  const sectionTypes = useMemo(() => {
    return layout?.Layout?.Sections?.reduce(
      (prev, item) => {
        if (item?.Type) {
          return { ...prev, [item?.Type]: true };
        }
        return prev;
      },
      {
        disclaimers: false,
        'custom-question': false,
        'custom-details': false,
      }
    );
  });

  const [modal, setModal] = useState('');
  const needsAttention = getProductNeedsAttention(product);

  const productConfig = productTypeConfigs?.[product?.Type]?.() || {};

  const sections = useMemo(() => {
    let sections = layout?.Layout?.Sections || [];
    if (product?.Type === 'insurance_plan') {
      // TODO: BRI-2799 - :throw-up:
      // We need to use product-layout ordering logic, not this BS
      const order = ['Medical Details', 'Network Information', 'Plan Details', 'Premiums & Contributions'];
      return sections.sort((a, b) => {
        const aIdx = order.indexOf(a.DisplayValue);
        const bIdx = order.indexOf(b.DisplayValue);
        return aIdx - bIdx;
      });
    }
    return sections;
  });

  return (
    <>
      <Div
        css={css`
          width: 100%;
          min-width: 600px;
          max-width: 900px;
          padding-top: ${px.xl};
          padding-bottom: 200px;
          margin: 0 ${px.xl};
        `}
      >
        {devMode ? (
          <Div
            css={css`
              ${flex('left')}
              button {
                margin-right: 16px;
              }
            `}
          >
            <Button styles="icon" onClick={() => setModal('dev-settings')}>
              <Cpu />
            </Button>
            <Text h4>Dev Settings</Text>
          </Div>
        ) : null}

        {productsWithBuyups.includes(product?.Type) ? <MultiNetwork state={state} /> : null}

        {sections?.map((section, idx) => (
          <ProductSection
            key={idx}
            index={idx}
            state={state}
            section={section}
            needsAttention={needsAttention}
            openSections={openSections}
          />
        ))}

        {product?.Type === 'critical_illness' && <ConditionPercentages state={state} />}

        {product?.Type === 'insurance_plan' && network.category === 'core' && <SpendingAccounts state={state} />}

        {product?.Type === 'insurance_plan' && network.category === 'core' && <IncludedPrograms state={state} />}

        {!sectionTypes?.['custom-details'] ? <CustomDetailSectionWrapper state={state} /> : null}

        {!productConfig?.hideOptionalDetails && !sectionTypes?.['disclaimers'] ? (
          <OptionalDetails state={state} />
        ) : null}
      </Div>

      <ProductDevSettings display={modal === 'dev-settings' && devMode} onClose={() => setModal('')} state={state} />
    </>
  );
};
