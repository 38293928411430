import { useMemo } from 'react';
import { LayoutContainer } from './layout-container';
import { Select } from '../../shared/components';
import { MenuItem } from '@material-ui/core';
import { availableCategoryTypes, networkMedicalMap } from '../multi-network';
import { colors } from '../../shared/styles';

export const NetworkTypes = (props) => {
  const { value, updateProperty, field, state } = props;
  const availableTypes = useMemo(() => {
    const activeNetworks = Object.keys(state?.network?.activeCategories || {});
    return availableCategoryTypes?.filter((type) => !activeNetworks.includes(type) || type === value);
  });

  return (
    <LayoutContainer {...props} hideBorder={true}>
      <Select
        disabled={!value || value === 'core'}
        value={value || 'core'}
        onChange={(e) => updateProperty(field?.PropertyChain, e.target.value)}
        css={`
          width: 100%;
          ${!value || value === 'core' ? `background-color: ${colors.gray[100]};` : ''}
        `}
      >
        {!value || value === 'core' ? <MenuItem value="core">Primary</MenuItem> : null}
        {availableTypes?.map((type) => (
          <MenuItem value={type}>{networkMedicalMap?.[type]}</MenuItem>
        ))}
      </Select>
    </LayoutContainer>
  );
};
