import { Plus, Star, X } from 'phosphor-react';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, Text, Box, Input } from '../shared/components';
import { colors } from '../shared/styles';
import { productUtils } from './configs/product-utils';
import { CustomAxios } from '../redux/axios/axios';
import { useModalWithData } from '../common/hooks/useModalWithData';
import { Tooltip } from '../common/components/Tooltip';

export const networkMedicalMap = {
  core: 'Primary',
  buyup: 'Buy Up',
  narrow: 'Narrow',
  other: 'Other',
};

const networkMapAll = {
  core: 'Primary',
  buyup: 'Buy Up',
};

export const availableCategoryTypes = ['core', 'other', 'buyup', 'narrow'];

const getNetworkCategory = (activeList) => {
  const availableCategory = availableCategoryTypes?.find((type) => !activeList.includes(type));
  return availableCategory;
};

export const getNetworkMap = (type) => (type === 'insurance_plan' ? networkMedicalMap : networkMapAll);

export const MultiNetwork = ({ state }) => {
  const { product, network, hasChanges, setLoadState, refetch } = state;
  const NETWORK_COUNT = product?.Type === 'insurance_plan' ? 3 : 2;

  const networkMap = getNetworkMap(product?.Type);

  const modal = useModalWithData(false, '');
  const [networkName, setNetworkName] = useState('');

  const activeCategoriesList = Object.keys(network.activeCategories);

  const tabs = useMemo(() => {
    const list = activeCategoriesList.reduce((prev, key) => {
      const product = network.getProductByCategory(key);
      const name = product?.Details?.NetworkName || networkMap[key];
      return [
        ...prev,
        {
          key,
          name,
          createdAt: product?.CreatedAt,
        },
      ];
    }, []);
    return list.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
  });

  const saveChanges = async () => {
    if (hasChanges) {
      try {
        await productUtils.saveProduct(state);
      } catch {
        toast.error('Failed to save changes, try again.');
      }
    }
  };

  const handleChange = async (key) => {
    await saveChanges();
    network.setCategoryByProduct(key);
  };

  const createNetwork = async (e) => {
    e.preventDefault();
    modal.show('create-network');
    const networkCategory = getNetworkCategory(activeCategoriesList);
    try {
      setLoadState(true);
      await productUtils.createNetwork(state, {
        MultiNetworkCategory: networkCategory,
        NetworkName: networkName,
      });
      network.setCategoryByProduct(networkCategory);
    } catch {
      toast.error('Failed to create network, try again.');
    } finally {
      setLoadState(false);
    }
  };

  const deleteNetwork = async (e) => {
    e.stopPropagation();
    try {
      const category = modal.data.category;
      modal.hide();
      setLoadState(true);
      const core = network.getProductByCategory('core');
      const product = network.getProductByCategory(category);
      await CustomAxios.delete(`/v1/plans/${product?.ID}`);
      if (Object.keys(network.activeCategories).length === 2) {
        await CustomAxios.put(`/v2/product/${core.ID}`, {
          ...core,
          MultiNetworkID: null,
          MultiNetworkCategory: '',
        });
      }
      network.setCategoryByProduct('core');
      toast.success('Network deleted!');
    } catch (e) {
      console.log(e);
      toast.error('Failed to delete network, try again');
    } finally {
      await refetch();
      setLoadState(false);
    }
  };

  return (
    <Box
      css={`
        width: 100%;
        margin-bottom: 24px;
        margin-top: 24px;
        border-bottom: 1px solid ${colors.gray[300]};
      `}
      flex="space-between"
    >
      {tabs?.map((value) => (
        <Box
          flex="center"
          css={`
            position: relative;
            flex-grow: 1;
            width: 100%;
            ${network?.category === value.key
              ? `
                border-bottom: 4px solid ${colors.black};
                margin-bottom: -1px;
              `
              : `
                border-bottom: 4px solid transparent;
                
            `}
            :hover {
              background-color: ${colors.gray[100]};
            }
            padding: 8px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            cursor: pointer;
            > svg {
              margin-right: 8px;
            }
            :not(:hover) {
              button {
                display: none;
              }
            }
          `}
          onClick={() => handleChange(value.key)}
        >
          {value.key === 'core' ? (
            <Tooltip
              label={
                <Box>
                  <Box
                    flex="left"
                    css={`
                      margin-bottom: 8px;
                      gap: 8px;
                    `}
                  >
                    <Star size={16} weight="fill" color="white" />
                    <Text h4>Primary Network</Text>
                  </Box>
                  <Text
                    css={`
                      font-weight: normal;
                    `}
                  >
                    Plan information, spending accounts, and included programs can only be configured on the core
                    network.
                  </Text>
                </Box>
              }
            >
              <Star size={20} weight="fill" />
            </Tooltip>
          ) : (
            <Button
              icon
              css={`
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 24px;
                padding: 0;
                border-radius: 4px;
              `}
              onClick={() => modal.show({ type: 'delete-network', category: value.key })}
            >
              <X size={16} />
            </Button>
          )}
          <Text h4 ellipsis>
            {value?.name}
          </Text>
        </Box>
      ))}
      {tabs?.length < NETWORK_COUNT ? (
        <Box
          css={`
            width: 100%;
            flex-grow: 1;
            p,
            svg {
              color: ${colors.gray[400]};
            }
            svg {
              margin-right: 8px;
            }
            flex-grow: 1;
            padding: 8px;
            margin-bottom: -1px;
            :hover {
              background-color: ${colors.gray[100]};
              p,
              svg {
                color: ${colors.black};
              }
            }
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            cursor: pointer;
          `}
          flex="center"
          onClick={() => modal.show('create-network')}
        >
          <Plus size={24} weight="bold" color="currentColor" />
          <Text h4>Add Network</Text>
        </Box>
      ) : null}
      {modal.data === 'create-network' ? (
        <Modal display={true} onClose={modal.hide}>
          <Modal.Paper>
            <Modal.Header title="Add Network" onClose={modal.hide} />
            <form onSubmit={createNetwork}>
              <Modal.Body>
                <Text label>Network Name</Text>
                <Input value={networkName} onChange={(e) => setNetworkName(e.target.value)} />
              </Modal.Body>
              <Modal.Actions>
                <Button secondary onClick={createNetwork}>
                  Skip
                </Button>
                <Button type="submit" disabled={!networkName}>
                  Add
                </Button>
              </Modal.Actions>
            </form>
          </Modal.Paper>
        </Modal>
      ) : modal?.data?.type === 'delete-network' ? (
        <Modal display={true} onClose={modal.hide}>
          <Modal.Paper>
            <Modal.Header title="Are you sure?" onClose={modal.hide} />
            <form onSubmit={deleteNetwork}>
              <Modal.Body>
                <Text label>This action cannot be undone. Are you sure you want to delete this network?</Text>
              </Modal.Body>
              <Modal.Actions>
                <Button secondary onClick={modal.hide}>
                  Cancel
                </Button>
                <Button type="submit" red>
                  Delete
                </Button>
              </Modal.Actions>
            </form>
          </Modal.Paper>
        </Modal>
      ) : null}
    </Box>
  );
};
