import { cloneDeep, get, set } from 'lodash';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { createProduct, getRawData } from '../../benefit-package/creation-flow/utils';
import { surestPlanType } from '../../Content/decision-tool-constants';
import { CustomAxios } from '../../redux/axios/axios';
import { defaultHSAContributions } from '../product-layout-constants';
import { aiSuggestions, fieldHistory } from '../../react-query';
import { advancedInputFields, formFieldConfigs, surestInputFields } from './form.config';
import { getFieldConfigs } from './config-utils';
import { getStoredValue } from '../field-components/field-input';

const exclusiveMedicalNetworkFields = {
  ID: null,
  'Details.NetworkProviderSearchUrl': '',
  'Details.NetworkDescription': '',
  ApplicableStates: ['ALL'],
  'Details.NetworkName': ({ NetworkName }) => NetworkName || '',
  MultiNetworkCategory: ({ MultiNetworkCategory }) => MultiNetworkCategory,
  MultiNetworkID: ({ MultiNetworkID }) => MultiNetworkID,
  HideEmployerPremiumContributions: false,
  'Details.HSACompatible': false,
  HSAContributionType: 'HSA - No Employer Contribution',
  HSAContributions: defaultHSAContributions,
};

// Copy CORE properties to BUYUP that aren't included in exclusiveMedicalNetworkFields.
// Those are also the default values for each of the fields.
const reconcileMedicalNetworks = (sections, product, core) => {
  if (
    product?.MultiNetworkCategory === 'buyup' ||
    product?.MultiNetworkCategory === 'narrow' ||
    product?.MultiNetworkCategory === 'other'
  ) {
    let data = cloneDeep(product);
    const syncedSections = data?.MultiNetworkSectionSync?.map((item) => item?.Name);
    set(data, 'Details.PlanType', core?.Details?.PlanType);
    for (let sectionIdx = 0; sectionIdx < sections?.length; sectionIdx++) {
      if (!syncedSections?.includes(sections?.[sectionIdx]?.DisplayValue)) {
        continue;
      }
      for (let idx = 0; idx < sections?.[sectionIdx]?.Fields?.length; idx++) {
        const field = sections?.[sectionIdx]?.Fields?.[idx];
        if (field?.PropertyChain && !(field?.PropertyChain in exclusiveMedicalNetworkFields)) {
          const value = get(core, field.PropertyChain);
          set(data, field.PropertyChain, value);
        }
      }
    }
    return data;
  }
  return core;
};

// **********************************************************************
// ***** Create Medical Network Product
// **********************************************************************

const createMedicalNetworkProduct = (core, changes = {}) => {
  let { ID, ...clone } = cloneDeep(core);
  for (const entry of Object.entries(exclusiveMedicalNetworkFields)) {
    const [property, value] = entry;
    if (value !== null) {
      const item = typeof value === 'function' ? value(changes) : value;
      set(clone, property, item);
    }
  }
  let coreUpdates = null;
  if (!core?.MultiNetworkID) {
    coreUpdates = { MultiNetworkID: changes?.MultiNetworkID, MultiNetworkCategory: 'core' };
  }
  return {
    data: {
      ...clone,
      MultiNetworkSectionSync: [
        {
          Name: 'Plan Details',
        },
      ],
    },
    coreUpdates,
  };
};

// **********************************************************************
// ***** Create Non-Medical Network Product
// **********************************************************************

export const createNetwork = async (data, changes = {}) => {
  const { packageId, product, network, refetch, dispatch } = data;

  const core = network.getProductByCategory('core');

  try {
    let response;
    if (product?.Type === 'insurance_plan') {
      const MultiNetworkID = core?.MultiNetworkID || uuidv4();
      const { data, coreUpdates } = createMedicalNetworkProduct(core, {
        ...changes,
        MultiNetworkID,
      });
      if (coreUpdates !== null) {
        await CustomAxios.put(`/v2/product/${core?.ID}`, {
          ...core,
          ...coreUpdates,
        });
      }
      response = await CustomAxios.post(`/v2/product`, { ...data });
    } else {
      const MultiNetworkID = core?.MultiNetworkID || uuidv4();
      const { ID, ...body } = cloneDeep(core);
      const Details = getRawData(body?.Type);
      if (!core?.MultiNetworkID) {
        await CustomAxios.put(`/v2/product/${ID}`, {
          ...core,
          MultiNetworkID,
          MultiNetworkCategory: 'core',
        });
      }
      response = await CustomAxios.post(`/v2/product`, {
        ...body,
        Details,
        ProductNetworkName: changes?.NetworkName || '',
        MultiNetworkID,
        MultiNetworkCategory: changes?.MultiNetworkCategory,
        ProductName: '',
        TitleDescriptions: [],
      });
    }
    const { data } = response;
    dispatch({
      type: 'set-selected-product-id',
      id: data.ID,
    });
    await CustomAxios.put(`/v1/benefitspackage/${packageId}/product/${data.ID}`);
  } catch (err) {
    console.warn(err);
    toast.error(`Failed to create new network.`);
    throw err;
  } finally {
    await refetch();
  }
};

// **********************************************************************
// ***** PRODUCT UTILS:
// **********************************************************************

// ***** Save Layout
const saveLayout = async (state) => {
  const { product, layout } = state;
  if (!!product?.ID) {
    try {
      await CustomAxios.post(`/v1/product-layout/product/${product?.ID}`, layout);
    } catch (err) {
      console.warn(err);
      toast.error(`There was an error saving the product layout.`);
    }
  }
};

// ***** Save Included Programs ****************************************

const saveIncludedPrograms = (data) => {
  const { includedPrograms, network, businessId } = data;
  const oldProgramData = data?.getIncludedProgramsQueryData();
  const oldPrograms = Object.keys(oldProgramData);
  const programs = Object.values(includedPrograms);
  const core = network.getProductByCategory('core');

  const deletePrograms = oldPrograms
    .map((type) => {
      const item = includedPrograms?.[type] || null;

      if ((item !== null && item?.deleted && item.ID) || item === null) {
        return ['delete', `/v1/plans/${core?.ID}/programs/${oldProgramData?.[type]?.ID}`, null];
      }
      return [];
    })
    .filter((item) => item?.length);

  const updatePrograms = programs
    .map((item) => {
      let updates = { ...item };
      if ('Details' in (item?.RawData || {})) {
        updates = {
          ...item,
          RawData: { ...(item?.RawData || {}), Type: item?.ProgramType },
        };
      }
      if (!item.deleted) {
        if (item.ID) {
          return ['put', `/v1/plans/${core?.ID}/programs/${item?.ID}`, updates];
        } else {
          return [
            'post',
            `/v1/plans/${core?.ID}/programs`,
            {
              PlanID: core?.ID,
              BusinessID: businessId,
              ...updates,
            },
          ];
        }
      }
      return item;
    })
    .filter((item) => item?.length);
  return [...deletePrograms, ...updatePrograms];
};

// ***** Save Custom Question Requests ****************************************

const getSaveCustomQuestion = (state) => {
  const { customQuestion, product, businessId } = state;
  if (!customQuestion?.ID) {
    return [
      'post',
      `/v1/decisiontool/questions`,
      {
        BusinessID: businessId,
        ProductID: product?.ID,
        ...customQuestion,
      },
    ];
  } else {
    return ['put', `/v1/decisiontool/questions/${customQuestion?.ID}`, { ...customQuestion }];
  }
};

// ***** Save Entire Product State ******************************************

const saveProduct = async (state, options = {}) => {
  const { shouldRefetch = true } = options;
  const { product, layout, network, refetch, hasChangesList } = state;
  if (product?.ID) {
    try {
      const core = network.getProductByCategory('core');
      let promises = [];
      if (core?.Type === 'insurance_plan' && hasChangesList.includedPrograms) {
        promises = saveIncludedPrograms(state);
      }
      if (core?.Type === 'custom' && hasChangesList.customQuestion) {
        promises = promises.concat([getSaveCustomQuestion(state)]);
      }
      await Promise.allSettled([
        // Handle Layout request
        saveLayout(state),
        /// HANDLE INCLUDED PROGRAMS REQUESTS
        ...promises.map(([method, url, body]) => {
          if (body === null) {
            return CustomAxios[method](url);
          } else {
            return CustomAxios[method](url, body);
          }
        }),
        /// HANDLE PRODUCT REQUESTS
        ...Object.keys(network.activeCategories).map((category) => {
          const product = network.getProductByCategory(category);
          let body = product;
          body = set(body, 'Details.SurestProfessionalServices', {
            ActuarailCost: body?.Details?.SurestProfessionalServices?.ActuarailCost,
          });
          if (product?.Type === 'insurance_plan') {
            if (product?.MultiNetworkID) {
              body = reconcileMedicalNetworks(layout?.Layout?.Sections, body, core);
            }
            body = set(body, 'Details.HSAContributionType', 'deprecated');
          }
          return CustomAxios.put(`/v2/product/${product.ID}`, body);
        }),
      ]);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      if (shouldRefetch) {
        await refetch();
      }
    }
  }
};

const pointStateToCore = (state) => {
  const { network } = state;
  const core = network.getProductByCategory('core');
  return {
    ...state,
    product: core,
    network: {
      ...state.network,
      category: 'core',
    },
  };
};

export const getLayoutObject = (state) => {
  const { layout } = state;
  return layout?.Layout?.Sections?.reduce((prev, item, sectionIdx) => {
    return item?.Fields?.reduce(
      (p, item, fieldIdx) => ({
        ...p,
        [item?.PropertyChain]: { ...item, fieldIdx, sectionIdx },
      }),
      prev
    );
  }, {});
};

const selectSuggestion = (state, source, propertyChain) => {
  const { dispatch } = state;
  const { data } = state?.aiSuggestions?.query;
  const suggestion = data?.find((item) => item?.property_chain === propertyChain);
  if (suggestion) {
    dispatch({
      type: 'select-suggestion',
      data: suggestion,
      source,
      status: 'selected',
    });
  } else {
    dispatch({ type: 'reset-suggestion' });
  }
};

const updateSuggestionStatus = (state, status) => {
  state?.dispatch({
    type: 'set-selected-suggestions-status',
    data: status,
  });
};

const updateHistoryFromSuggestion = async (state, suggestion, requesters) => {
  try {
    const body = fieldHistory.utils.getFieldHistoryFromSuggestion(state?.fieldHistory, suggestion);

    const next = state?.aiSuggestions?.query?.data?.filter((item) => item?.id !== suggestion?.id);
    state?.aiSuggestions?.setData(next);

    const putFieldHistory = fieldHistory.utils.getPost(body);
    const history = await requesters?.fieldHistory?.post({
      ...putFieldHistory,
      refetch: false,
    });

    const updates = {
      ...suggestion,
      product_field_history_id: history.data[0].id,
    };

    const putSuggestion = aiSuggestions.utils.getPut(suggestion?.id, updates);

    await requesters?.aiSuggestions?.put({
      ...putSuggestion,
      refetch: false,
    });
  } catch (err) {
    console.log(err);
  }
};

const initiateSave = (state) => {
  state?.dispatch({ type: 'initiate-save' });
};

// **********************************************************************
// ***** PRODUCT VALIDATORS:
// **********************************************************************

export const productValidators = (state) => ({
  customQuestion: !state?.customQuestion?.Text || !!state?.customQuestion?.Responses?.length,
});

// **********************************************************************
// ***** PRODUCT MODIFIERS:
// **********************************************************************

// ***** Set Product ******************************************
const setProduct = (state, valueFn, options = {}) => {
  const { dispatch, network } = state;
  const { category = '' } = options;
  const editingProduct = network.getProductByCategory(category);
  let next = valueFn;
  if (typeof valueFn === 'function') {
    next = valueFn(editingProduct);
  }
  dispatch({
    type: 'set-product',
    data: next,
  });
};

const mergeProduct = (state, updates, options = {}) => {
  const { dispatch } = state;
  dispatch({
    type: 'merge-product',
    updates,
  });
};

// ***** Set Layout ******************************************
const setLayout = (state, valueFn, options = {}) => {
  const { dispatch, layout } = state;
  let next = valueFn;
  if (typeof valueFn === 'function') {
    next = valueFn(layout);
  }
  dispatch({
    type: 'set-layout',
    data: next,
  });
};

// ***** Set Custom Question ******************************************

const setCustomQuestion = (state, valueFn, options = {}) => {
  const { dispatch, customQuestion } = state;
  let next = valueFn;
  if (typeof valueFn === 'function') {
    next = valueFn(customQuestion);
  }
  dispatch({
    type: 'set-custom-question',
    data: next,
  });
};

// ***** Set Included Programs ******************************************

const setIncludedPrograms = (state, valueFn, options = {}) => {
  const { dispatch, includedPrograms } = state;
  let next = valueFn;
  if (typeof valueFn === 'function') {
    next = valueFn(includedPrograms);
  }
  dispatch({
    type: 'set-included-programs',
    data: next,
  });
};

// ***** Toggle Included Program ******************************************

const toggleProgram = (state, programType, options = {}) => {
  const { includedPrograms, getResetProgramData } = state;

  if (programType in includedPrograms) {
    setIncludedPrograms(state, (programs) => {
      let next = { ...programs };
      delete next[programType];
      return next;
    });
  } else {
    const program = getResetProgramData(programType);
    setIncludedPrograms(state, (programs) => ({
      ...programs,
      [programType]: program,
    }));
  }
};

export const productModifier = {
  setProduct,
  mergeProduct,
  setLayout,
  setCustomQuestion,
  setIncludedPrograms,
  toggleProgram,
};

// **********************************************************************
// ***** Handle Product-Layout Form Triggers
// **********************************************************************

const formTrigger = (state, value, { field, config, oldValue }) => {
  const { network } = state;

  const { PropertyChain: property } = field;

  if (property === 'Details.PlanType' && value?.toUpperCase() === surestPlanType) {
    toast.warn('Some fields have been updated to match the selected plan type.');
  }

  if (config?.layoutTrigger) {
    productModifier.setLayout(state, (layoutData) => {
      const propertiesToUpdate = config?.layoutTrigger?.(value, oldValue);
      let Sections = [...layoutData?.Layout.Sections];
      for (let sectionIdx = 0; sectionIdx < layoutData?.Layout?.Sections?.length; sectionIdx++) {
        let Fields = [...layoutData?.Layout.Sections?.[sectionIdx].Fields];
        for (let fieldIdx = 0; fieldIdx < layoutData?.Layout.Sections[sectionIdx].Fields?.length; fieldIdx++) {
          const field = layoutData?.Layout.Sections[sectionIdx].Fields[fieldIdx];
          if (field.PropertyChain in propertiesToUpdate) {
            const updates = propertiesToUpdate[field.PropertyChain];
            Fields[fieldIdx] = { ...field, ...updates };
          }
        }
        Sections[sectionIdx] = { ...Sections[sectionIdx], Fields };
      }
      return {
        ...layoutData,
        Layout: { ...layoutData?.Layout, Sections },
      };
    });
  }

  let updates = {
    [property]: value,
  };
  if (config?.productTrigger) {
    const triggerUpdates = config?.productTrigger?.(value);
    updates = {
      ...updates,
      ...triggerUpdates,
    };
  }
  const list = Object.entries(updates);
  productModifier.setProduct(
    state,
    (product) => {
      let next = cloneDeep(product);
      for (const item of list) {
        const [property, value] = item;
        next = set(next, property, value);
      }
      return next;
    },
    { category: network?.category }
  );
};

export const productUtils = {
  createProduct: (data, product) => createProduct(product, data),
  createNetwork,
  formTrigger,
  saveProduct,
  pointStateToCore,
  selectSuggestion,
  updateSuggestionStatus,
  updateHistoryFromSuggestion,
  initiateSave,
};

export const setPropertyChain = (state, propertyChain, nextValue) => {
  const configs = getFieldConfigs(formFieldConfigs, state);
  const config = configs?.[propertyChain];
  const field = state?.fieldsObject?.[propertyChain];
  const value = getStoredValue(field, config, nextValue);

  const oldValue = get(state.product, propertyChain);

  const { network } = state;

  if (config?.layoutTrigger) {
    productModifier.setLayout(state, (layoutData) => {
      const propertiesToUpdate = config?.layoutTrigger?.(value, oldValue);
      let Sections = [...layoutData?.Layout.Sections];
      for (let sectionIdx = 0; sectionIdx < layoutData?.Layout?.Sections?.length; sectionIdx++) {
        let Fields = [...layoutData?.Layout.Sections?.[sectionIdx].Fields];
        for (let fieldIdx = 0; fieldIdx < layoutData?.Layout.Sections[sectionIdx].Fields?.length; fieldIdx++) {
          const field = layoutData?.Layout.Sections[sectionIdx].Fields[fieldIdx];
          if (field.PropertyChain in propertiesToUpdate) {
            const updates = propertiesToUpdate[field.PropertyChain];
            Fields[fieldIdx] = { ...field, ...updates };
          }
        }
        Sections[sectionIdx] = { ...Sections[sectionIdx], Fields };
      }
      return {
        ...layoutData,
        Layout: { ...layoutData?.Layout, Sections },
      };
    });
  }

  let updates = {
    [propertyChain]: value,
  };
  if (config?.productTrigger) {
    const triggerUpdates = config?.productTrigger?.(value);
    updates = {
      ...updates,
      ...triggerUpdates,
    };
  }
  const list = Object.entries(updates);
  productModifier.setProduct(
    state,
    (product) => {
      let next = cloneDeep(product);
      for (const item of list) {
        const [property, value] = item;
        next = set(next, property, value);
      }
      return next;
    },
    { category: network?.category }
  );
};

export const migrateSurestProduct = (layout, dispatch) => {
  const fields = layout?.Layout?.Sections?.reduce((prev, section) => {
    return section?.Fields?.reduce((p, field) => {
      return [...p, field];
    }, prev);
  }, []);

  const surest = fields?.filter(({ PropertyChain }) => surestInputFields?.includes(PropertyChain));

  if (surest?.length) {
    const nonSurest = fields?.filter(({ PropertyChain }) => advancedInputFields?.includes(PropertyChain));
    const everyNonSurestIsHidden = nonSurest?.every(({ State }) => State === 'hide');

    const nextSections = layout?.Layout?.Sections?.reduce((prev, section) => {
      const Fields = section?.Fields?.reduce((p, item) => {
        if (advancedInputFields?.includes(item?.PropertyChain)) {
          return [
            ...p,
            {
              ...item,
              State: everyNonSurestIsHidden ? 'show' : item?.State,
            },
          ];
        } else if (surestInputFields?.includes(item?.PropertyChain)) {
          return p;
        }
        return [...p, item];
      }, []);
      return [
        ...prev,
        {
          ...section,
          Fields,
        },
      ];
    }, []);
    dispatch({
      type: 'set-layout',
      data: {
        ...layout,
        Layout: {
          ...layout?.Layout,
          Sections: nextSections,
        },
      },
    });
    dispatch({ type: 'initiate-save' });
  }
};
