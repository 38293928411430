import { MenuItem } from '@material-ui/core';
import { css, cx } from '@emotion/css';
import { CurrencyDollarSimple, DotsThreeVertical, EyeSlash, Percent, StarFour } from 'phosphor-react';
import {
  getTierStructure,
  tierStructures,
  useDisplaySettings,
  getTierOptions,
} from '../../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings';
import { Button, Div, DropMenu, Select, Text } from '../../shared/components';
import { flex, px } from '../../shared/shared-styles';
import { colors, mediaQueryFn } from '../../shared/styles';
import { FieldInput, getStoredValue } from './field-input';
import { fieldContainerStyle } from './field';
import { defaultCost } from '../product-layout-constants';
import { set } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { getFieldProps } from './field-utils';
import { EditLayoutButton } from './layout-container';
import { productModifier, productUtils } from '../configs/product-utils';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { Flag } from '../flags/flag';
import { needsAttention } from '../../react-query/resources/needs-attention';
import { BriteLoader } from '../../shared/components/brite-loader';
import { Tooltip } from '../../common/components/Tooltip';

const tabContainer = (selected, disabled) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.gray[400]};
  border-radius: 8px;
  padding: 8px 16px;
  width: calc(50% - 4px);
  box-sizing: border-box;
  ${disabled
    ? `background-color: ${colors.gray[100]}`
    : `
    cursor: pointer;
    :hover {
      ${selected ? '' : `border: 1px solid ${colors.black};`}
    }
  `}
  ${selected &&
  `
  padding: 7px 15px;
  background-color: #F5F7FA;
  border: 2px solid black;
`}
`;

export const Cost = ({ state, field, config }) => {
  const ref = useRef();

  const costTiersFF = useFeatureFlagPayload('tier-structure');
  const twelveTierFF = useFeatureFlagPayload('twelve-tiers');

  const { product, layout, businessId, packageId } = state;

  const premiumsSetExternally = product?.Details?.PlanType?.startsWith('aca');

  const { value, updateProperty, updateLayout } = getFieldProps(state, field, config);

  const [stateSyncedAt, setStateSyncedAt] = useState('');

  const hideEmployerContributions = layout?.HideEmployerPremiumContributions;
  const cost = value || defaultCost;
  const { displaySettings } = useDisplaySettings(businessId, packageId);
  const tiers = getTierStructure({
    featureFlag: costTiersFF?.value,
    cost: product?.Cost,
    displaySettings,
  });
  const contributionType = cost?.Contributions?.ContributionType || '$';
  const contributionFieldType = contributionType === '$' ? 'dollar' : contributionType === '%' ? 'percent' : '';

  const handlePasteValue = (fieldLayout, value) => {
    try {
      let updates = { ...cost };
      const property = fieldLayout?.PropertyChain?.replace('Cost.', '');
      const nextValue = getStoredValue(fieldLayout, value);
      set(updates, property, nextValue);

      const dollarIndex = value?.indexOf('$');
      const percentIndex = value?.indexOf('%');

      if (percentIndex > dollarIndex) {
        set(updates, 'Contributions.ContributionType', '%');
      } else if (dollarIndex > percentIndex) {
        set(updates, 'Contributions.ContributionType', '$');
      }

      updateProperty('Cost', updates);
      setStateSyncedAt(new Date().toISOString());
    } catch (err) {
      console.log(err);
    }
  };

  const updateHideEmployerContributions = (value) => {
    productModifier.setLayout(state, (productLayout) => {
      return {
        ...productLayout,
        HideEmployerPremiumContributions: value,
      };
    });
  };

  const setTierIndex = (val) => {
    productModifier?.setProduct(state, (p) => ({
      ...p,
      Cost: {
        ...p?.Cost,
        Contributions: {
          ...p?.Cost?.Contributions,
          MonthlyContributions: {
            ...p?.Cost?.Contributions?.MonthlyContributions,
            Tiers: [...tierStructures[val]],
          },
        },
        TotalMonthlyPremiums: {
          ...p?.Cost?.TotalMonthlyPremiums,
          Tiers: [...tierStructures[val]],
        },
      },
    }));
  };

  const getFlag = (property) => {
    return needsAttention.utils.getFlagByPropertyChain(state?.flags, property);
  };

  useEffect(() => {
    if (state?.suggestion?.data?.property_chain?.includes('Cost')) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state?.suggestion?.data?.property_chain]);

  const getIsCurrentSuggestion = (property) => {
    return property === state?.suggestion?.data?.property_chain;
  };

  const getCostSuggestion = (propertyChain) => {
    return (
      state?.aiSuggestions?.query?.data?.find(
        ({ property_chain, status }) =>
          property_chain === propertyChain && (status === 'completed' || status === 'pending')
      ) || {}
    );
  };

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      {costTiersFF?.value ? (
        <Div
          css={css`
            ${flex('space-between')}
          `}
        >
          <Text label>Tier Rate Structure</Text>
          <Select
            value={tiers?.tierStructure}
            disabled={config?.disabled}
            onChange={(e) => setTierIndex(e.target.value)}
            css={`
              width: 50%;
              ${config?.disabled ? `background-color: ${colors.gray[100]};` : ''}
            `}
          >
            {getTierOptions(twelveTierFF).map((tier) => (
              <MenuItem value={tier.value}>{tier.label}</MenuItem>
            ))}
          </Select>
        </Div>
      ) : null}

      {!premiumsSetExternally && (
        <>
          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text
              styles="h4"
              className={css`
                margin: ${px.lg} 0;
                margin-top: ${px.xl};
              `}
            >
              Total Monthly Premiums
            </Text>
            <EditLayoutButton field={field} updateLayout={updateLayout} hidden={['highlight', 'edit', 'flag']} />
          </Div>
          {tiers?.keys?.map((tier, idx) => {
            const propertyChain = `Cost.TotalMonthlyPremiums.${tier}`;
            const isCurrentSuggestion = getIsCurrentSuggestion(propertyChain);
            const suggestion = getCostSuggestion(propertyChain);
            return (
              <Div css={fieldContainerStyle()} key={tier} ref={isCurrentSuggestion ? ref : null}>
                <Text styles="label" className="label">
                  {tiers?.labels?.[tier]}
                </Text>
                <div
                  className={css`
                    width: 50%;
                    margin-right: -8px;
                  `}
                >
                  <div
                    className={css`
                      position: relative;
                      ${flex('right')}
                      .input-field {
                        flex-grow: 1;
                        ${isCurrentSuggestion
                          ? `
                          border: 1px solid ${colors.black};
                          outline: 1px solid ${colors.black};
                        `
                          : ''}
                      }
                    `}
                  >
                    {!!suggestion?.id ? (
                      suggestion?.status === 'completed' ? (
                        <Tooltip label="Auto-Fill Suggestion">
                          <div
                            className={css`
                              position: absolute;
                              left: -24px;
                              top: calc(50% - 8px);
                              cursor: pointer;
                            `}
                            onClick={() => productUtils?.selectSuggestion(state, 'field', propertyChain)}
                          >
                            <StarFour color={colors.purple} size={16} weight="fill" />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip label="Searching for a suggestion...">
                          <div
                            className={css`
                              position: absolute;
                              left: -32px;
                              top: calc(50% - 12px);
                            `}
                          >
                            <BriteLoader
                              size={24}
                              weight={12}
                              overlay={false}
                              css={`
                                margin-right: 16px;
                              `}
                            />
                          </div>
                        </Tooltip>
                      )
                    ) : null}
                    <FieldInput
                      className="input-field"
                      field={{
                        Type: 'dollar',
                        PropertyChain: propertyChain,
                        productId: product?.ID,
                      }}
                      config={{
                        storeValueAs: 'number',
                        roundUp: false,
                        disabled: config?.disabled,
                      }}
                      value={cost?.TotalMonthlyPremiums?.[tier]}
                      updateProperty={updateProperty}
                    />
                    <EditLayoutButton
                      state={state}
                      field={{
                        ...field,
                        PropertyChain: propertyChain,
                      }}
                      updateLayout={updateLayout}
                      hidden={['highlight', 'show', 'hide']}
                    />
                  </div>
                  <Flag flag={getFlag(propertyChain)} />
                </div>
              </Div>
            );
          })}
        </>
      )}
      <Div
        css={css`
          ${flex('space-between')}
        `}
      >
        <Text
          styles="h4"
          className={css`
            margin: ${px.lg} 0;
            margin-top: ${px.xl};
          `}
        >
          Employer Contributions
        </Text>

        <DropMenu
          anchorOrigin={{ horizontal: 'right' }}
          transformOrigin={{ horizontal: 290 }}
          button={
            <Button styles="icon sm">
              {hideEmployerContributions ? <EyeSlash size={24} /> : <DotsThreeVertical size={24} />}
            </Button>
          }
        >
          <MenuItem onClick={() => updateHideEmployerContributions(false)}>Show Employer Contributions</MenuItem>
          <MenuItem onClick={() => updateHideEmployerContributions(true)}>Hide Employer Contributions</MenuItem>
        </DropMenu>
      </Div>

      <Div css={fieldContainerStyle()}>
        <Text styles="label">Contribution Type</Text>
        <Div
          className={css`
            ${flex('space-between')} width: 50%;
            ${mediaQueryFn('less-than', '1000px')} {
              width: 100%;
            }
          `}
        >
          <Div
            className={tabContainer(contributionType === '$', config?.disabled)}
            disabled={config?.disabled}
            onClick={() => !config?.disabled && updateProperty(`Cost.Contributions.ContributionType`, '$')}
            tabIndex="-1"
          >
            <CurrencyDollarSimple color={contributionType === '$' ? 'black' : 'currentColor'} tabIndex="-1" />
            <Text color={contributionType === '$' ? colors.black : 'initial'} tabIndex="-1">
              Dollars
            </Text>
          </Div>
          <Div
            tabIndex="-1"
            className={tabContainer(contributionType === '%', config?.disabled)}
            disabled={config?.disabled}
            onClick={() => !config?.disabled && updateProperty(`Cost.Contributions.ContributionType`, '%')}
          >
            <Percent tabIndex="-1" color={contributionType === '%' ? 'black' : 'currentColor'} />
            <Text color={contributionType === '%' ? colors.black : 'initial'} tabIndex="-1">
              Percent
            </Text>
          </Div>
        </Div>
      </Div>

      {tiers?.keys?.map((tier) => {
        const propertyChain = `Cost.Contributions.MonthlyContributions.${tier}`;
        const isCurrentSuggestion = getIsCurrentSuggestion(propertyChain);
        const suggestion = getCostSuggestion(propertyChain);
        return (
          <Div
            css={cx(
              fieldContainerStyle(),
              css`
                .input-field {
                  width: 50%;
                }
              `
            )}
            key={tier}
            ref={isCurrentSuggestion ? ref : null}
          >
            <Text styles="label" className="label">
              {tiers?.labels?.[tier]}
            </Text>
            <div
              className={css`
                width: 50%;
                margin-right: -8px;
              `}
            >
              <div
                className={css`
                  position: relative;
                  ${flex('right')}
                  .input-field {
                    flex-grow: 1;
                    ${isCurrentSuggestion
                      ? `
                      border: 1px solid ${colors.black};
                      outline: 1px solid ${colors.black};
                    `
                      : ''}
                  }
                `}
              >
                {!!suggestion?.id ? (
                  suggestion?.status === 'completed' ? (
                    <Tooltip label="Auto-Fill Suggestion">
                      <div
                        className={css`
                          position: absolute;
                          left: -24px;
                          top: calc(50% - 8px);
                          cursor: pointer;
                        `}
                        onClick={() => productUtils?.selectSuggestion(state, 'field', propertyChain)}
                      >
                        <StarFour color={colors.purple} size={16} weight="fill" />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip label="Searching for a suggestion...">
                      <div
                        className={css`
                          position: absolute;
                          left: -32px;
                          top: calc(50% - 12px);
                        `}
                      >
                        <BriteLoader
                          size={24}
                          weight={12}
                          overlay={false}
                          css={`
                            margin-right: 16px;
                          `}
                        />
                      </div>
                    </Tooltip>
                  )
                ) : null}
                <FieldInput
                  className="input-field"
                  field={{
                    PropertyChain: propertyChain,
                    Type: contributionFieldType,
                    productId: product?.ID,
                  }}
                  config={{
                    storeValueAs: 'number',
                    roundUp: false,
                    disabled: config?.disabled,
                  }}
                  stateSyncedAt={stateSyncedAt}
                  handlePasteValue={handlePasteValue}
                  value={cost?.Contributions?.MonthlyContributions?.[tier]}
                  updateProperty={updateProperty}
                />
                <EditLayoutButton
                  state={state}
                  field={{
                    ...field,
                    State: hideEmployerContributions ? 'hide' : 'show',
                    PropertyChain: propertyChain,
                  }}
                  updateLayout={updateLayout}
                  hidden={['highlight', 'show', 'hide']}
                />
              </div>
              <Flag flag={getFlag(propertyChain)} />
            </div>
          </Div>
        );
      })}
    </Div>
  );
};
