import { isToday } from 'date-fns';
import { css } from '@emotion/css';
import { MagnifyingGlass } from 'phosphor-react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Div, Input, Modal, Text } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useDebounceValue } from '../shared/use-debounce-value';
import { useInput } from '../shared/use-input';
import { BenefitPackageList } from './benefit-package-list';
import { ACLWrapper } from '../shared/components/acl-wrapper';
import { WRITE_BENEFITS_PACKAGE } from '../shared/acl-constants';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { DocumentToBP } from './document-to-bp/document-to-bp';
import { Box } from '../shared/components/box';

const DEFAULT_PACKAGE_NAME = 'Package Name';

export const benefitProductsSelector = (data, key = 'CreatedAt') => {
  const list = data.sort((a, b) => a?.Name?.localeCompare(b?.Name));
  const { today, notToday } = list.reduce(
    (prev, item) => {
      if (isToday(new Date(item[key]))) {
        return { ...prev, today: [...prev.today, item] };
      }
      return { ...prev, notToday: [...prev.notToday, item] };
    },
    { today: [], notToday: [] }
  );
  return [...today, ...notToday];
};

export const BenefitPackagePage = () => {
  const { businessId } = useParams();

  const aiAutofill = useFeatureFlagPayload('ai-autofill');
  const [search, setSearch] = useState('');
  const [debounced_search] = useDebounceValue(search);

  const query = useQueryAPI({
    url: `v1/benefitspackage`,
    select: benefitProductsSelector,
  });

  const [pkgNameProps, setPkgName] = useInput(DEFAULT_PACKAGE_NAME);
  const [modal, setModal] = useState('');

  const closeModal = () => {
    setModal('');
    setPkgName(DEFAULT_PACKAGE_NAME);
  };

  const createPackage = async (e) => {
    e?.preventDefault();
    try {
      const body = { Name: pkgNameProps.value };
      query?.cache?.setData([{ ...body, CreatedAt: new Date().toISOString(), ProductCount: 0 }, ...query.data]);
      closeModal();
      await CustomAxios.post(`/v1/benefitspackage`, body);
      window?.ChurnZero?.push(['trackEvent', 'Benefit Package Created', `Name: ${pkgNameProps.value}`, 1]);
    } catch (err) {
      toast.error(`Error creating new package.`);
      console.warn(err);
    } finally {
      query.refetch();
    }
  };

  return (
    <>
      <Box
        page
        css={css`
          height: 100%;
        `}
      >
        <Div
          css={css`
            ${flex('space-between ai-center')}
          `}
        >
          <Text h1>Benefit Packages</Text>
          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Input
              styles="search"
              css={`
                margin-right: 16px;
                min-width: 300px;
              `}
              placeholder="Search"
              startIcon={<MagnifyingGlass color={colors.gray[500]} />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <ACLWrapper acls={[WRITE_BENEFITS_PACKAGE]}>
              <Button onClick={() => setModal('create-package')}>New Benefit Package</Button>
            </ACLWrapper>
          </Div>
        </Div>
        <Div
          css={css`
            position: relative;
            ${flex('center ai-center')} width: 100%;
          `}
        >
          <BriteLoader
            isLoading={query.isLoading}
            css={`
              height: calc(100vh - 180px);
              padding-bottom: 35vh;
            `}
          >
            <Text
              h2
              css={`
                margin: 32px 0;
              `}
            >
              Fetching Benefit Packages...
            </Text>
          </BriteLoader>
          <BenefitPackageList search={debounced_search} businessId={businessId} query={query} />
        </Div>
      </Box>

      <DocumentToBP
        display={aiAutofill?.value && modal === 'create-package'}
        startFromScratch={() => setModal('create-package-scratch')}
        onClose={closeModal}
      />

      <Modal
        display={(!aiAutofill?.value && modal === 'create-package') || modal === 'create-package-scratch'}
        onClose={closeModal}
      >
        <Modal.Paper>
          <Modal.Header title="Name this Benefit Package" onClose={closeModal} />
          <form onSubmit={createPackage}>
            <Modal.Body>
              <Text
                label
                bold
                css={`
                  margin-bottom: 8px;e
                `}
              >
                Name
              </Text>
              <Input
                {...pkgNameProps}
                onFocus={(event) => {
                  // Select the whole default value since we want them to overwrite it all.
                  event.target.select();
                }}
              />
            </Modal.Body>
            <Modal.Actions>
              <Button styles="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" disabled={!pkgNameProps.value}>
                Confirm
              </Button>
            </Modal.Actions>
          </form>
        </Modal.Paper>
      </Modal>
    </>
  );
};
