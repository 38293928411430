import { MenuItem, Radio } from '@material-ui/core';
import { css } from '@emotion/css';
import { surestPlanType } from '../../Content/decision-tool-constants';
import { Select, Div, Text } from '../../shared/components';
import { AdvancedInputField } from './advanced-input-field';
import { Cost } from './cost';
import { FieldInput } from './field-input';
import { DrugDeductible } from './drug-deductible';
import { SurestInputField } from './surest-input-field';
import { flex } from '../../shared/shared-styles';
import { LayoutContainer, LayoutSelect } from './layout-container';
import { useAcls } from '../../shared/use-acls';
import { WRITE_BENEFITS_PACKAGE } from '../../shared/acl-constants';

import { useMemo } from 'react';

import { colors } from '../../shared/styles';
import { Carriers } from './carriers';
import { needsAttention } from '../../react-query/resources/needs-attention';
import { Flag } from '../flags/flag';
import { ApplicableStates } from './applicable-states';
import { NetworkTypes } from './NetworkTypes';

export const planTypeOptions = ['PPO', 'HDHP', 'EPO', 'HMO', surestPlanType];
export const acaPlanTypeOptionsDisplay = ['PPO', 'HDHP', 'EPO', 'HMO'];

export const FieldTypes = (props) => {
  const { state, fieldProps, isSectionSyncing } = props;

  const { product, flags } = state;
  const { value, updateProperty, field, config = {} } = fieldProps;
  const { PropertyChain, Type } = field ?? {};

  const flag = needsAttention.utils.getFlagByPropertyChain(flags, field?.PropertyChain);

  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);

  const stateSyncedAt = useMemo(() => {
    new Date().toISOString();
  }, [value]);

  const nextConfig = {
    ...config,
    disabled: config.disabled || isSectionSyncing || !canEdit,
  };

  const basicProps = {
    state,
    field: { ...field, productId: product?.ID },
    config: nextConfig,
    value,
    updateProperty,
  };

  const fieldInputProps = {
    ...fieldProps,
    ...basicProps,
    stateSyncedAt,
  };

  const CustomFormField = useMemo(() => config?.customFormField, [stateSyncedAt, field]);

  return (
    <>
      <Div
        css={css`
          ${flex('left grow')}
        `}
      >
        {config?.customFormField ? (
          <CustomFormField state={state} fieldProps={fieldProps} />
        ) : Type === 'text-input' || Type === 'link' ? (
          // ************************* TEXT INPUT
          <LayoutContainer {...basicProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === 'text-area' ? (
          // ************************* TEXT AREA
          <LayoutContainer {...basicProps}>
            <FieldInput {...fieldInputProps} rows={3} />
          </LayoutContainer>
        ) : Type === 'number' ? (
          // ************************* TEXT NUMBER
          <LayoutContainer {...basicProps}>
            <FieldInput {...fieldInputProps} type="number" />
          </LayoutContainer>
        ) : Type === 'date' ? (
          // ************************* DATE
          <LayoutContainer {...basicProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === 'dollar' ? (
          // ************************* DOLLAR
          <LayoutContainer {...basicProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === 'multipleOfSalary' ? (
          <LayoutContainer {...basicProps}>
            <Div
              css={css`
                ${flex('space-between')}
              `}
            >
              <FieldInput {...fieldInputProps} />
              <Div
                css={css`
                  border-left: 1px solid ${colors.gray[300]};
                  padding: 0 16px;
                  margin-left: auto;
                  p {
                    min-width: max-content;
                  }
                `}
              >
                <Text label>X Salary</Text>
              </Div>
            </Div>
          </LayoutContainer>
        ) : Type === 'percent' || Type === 'percentOfEmployeeElectedAmount' ? (
          // ************************* PERCENT
          <LayoutContainer {...basicProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === 'toggle' ? (
          // ************************* TOGGLE
          <LayoutContainer {...basicProps} hideBorder={true}>
            <Div
              css={css`
                ${flex('space-between')}
                width: 100%;
                > div {
                  ${flex('space-between')}
                  border-radius: 8px;
                  padding: 2px 16px;
                  padding-right: 6px;
                  flex-grow: 1;
                  * {
                    pointer-events: none;
                  }
                }
              `}
            >
              <Div
                css={css`
                  margin-right: 8px;
                  ${nextConfig?.disabled
                    ? `
                    background-color: ${colors.gray[100]};  
                  `
                    : ''}
                  ${value
                    ? `
                    outline: 1px solid ${colors.black};
                    border: 1px solid ${colors.black};
                  `
                    : `border: 1px solid ${colors.gray[300]};`}
                `}
                onClick={() => (!nextConfig?.disabled ? updateProperty(PropertyChain, true) : null)}
              >
                <Text label bold={value}>
                  Yes
                </Text>
                <Radio color="primary" checked={value} disabled={nextConfig?.disabled} />
              </Div>
              <Div
                css={css`
                  margin-left: 8px;
                  ${nextConfig?.disabled
                    ? `
                    background-color: ${colors.gray[100]};  
                  `
                    : ''}
                  ${!value
                    ? `
                  border: 1px solid transparent;
                  outline: 2px solid ${colors.black};
                `
                    : `border: 1px solid ${colors.gray[300]};`}
                `}
                onClick={() => updateProperty(PropertyChain, false)}
              >
                <Text label bold={!value}>
                  No
                </Text>
                <Radio color="primary" checked={!value} disabled={nextConfig?.disabled} />
              </Div>
            </Div>
          </LayoutContainer>
        ) : Type === 'prescriptionDrugTier' ? (
          // ************************* Prescription Drug Tier Drop-Down
          <LayoutContainer {...basicProps} hideBorder={true}>
            <Select
              className={css`
                width: 100%;
                ${nextConfig?.disabled ? `background-color: ${colors.gray[100]};` : ''}
              `}
              disabled={nextConfig?.disabled}
              value={value}
              onChange={(e) => updateProperty(PropertyChain, e.target.value)}
              tabIndex="-1"
            >
              <MenuItem value="3 Tier Structure">3 Tier Structure</MenuItem>
              <MenuItem value="4 Tier Structure">4 Tier Structure</MenuItem>
              <MenuItem value="5 Tier Structure">5 Tier Structure</MenuItem>
              <MenuItem value="6 Tier Structure">6 Tier Structure</MenuItem>
            </Select>
          </LayoutContainer>
        ) : Type === 'prescriptionDrugDeductible' ? (
          // ************************* Prescription Drug Deductible Drop-Down
          <DrugDeductible {...basicProps} />
        ) : Type === 'medicalPlanTypes' ? (
          // ************************* Medical Plan Types
          <LayoutContainer {...basicProps} hideBorder={true}>
            <Select
              className={css`
                width: 100%;
              `}
              disabled={config?.disabled}
              value={value}
              onChange={(e) => updateProperty(PropertyChain, e.target.value)}
              tabIndex="-1"
            >
              {value.startsWith('aca')
                ? acaPlanTypeOptionsDisplay.map((item) => (
                    <MenuItem key={item} value={'acaplans-' + item.toLowerCase()}>
                      {item + ' (ACA)'}
                    </MenuItem>
                  ))
                : planTypeOptions.map((item) => (
                    <MenuItem key={item} value={item.toLowerCase()}>
                      {item}
                    </MenuItem>
                  ))}
            </Select>
          </LayoutContainer>
        ) : Type === 'dentalPlanTypes' ? (
          // ************************* Dental Plan Types
          <LayoutContainer {...basicProps} hideBorder={true}>
            <Select
              className={css`
                width: 100%;
              `}
              disabled={config?.disabled}
              value={value || ''}
              onChange={(e) => updateProperty(PropertyChain, e.target.value)}
              tabIndex="-1"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="PPO">PPO</MenuItem>
              <MenuItem value="HMO">HMO</MenuItem>
            </Select>
          </LayoutContainer>
        ) : Type === 'states' ? (
          // ************************* Applicable States
          <LayoutContainer {...basicProps} hideBorder={true}>
            <ApplicableStates value={value} onUpdate={(value) => updateProperty('ApplicableStates', value)} />
          </LayoutContainer>
        ) : Type === 'visionFrequency' ? (
          <LayoutContainer {...basicProps}>
            <div
              className={css`
                display: flex;
              `}
            >
              <FieldInput
                updateProperty={updateProperty}
                field={{ ...field, productId: product?.ID }}
                config={config}
                value={value.inputValue}
              />
              <LayoutSelect
                value={value.frequencyValue}
                onChange={(e) => {
                  updateProperty(fieldInputProps.field.frequency.PropertyChain, e.target.value);
                }}
                tabIndex="-1"
              >
                <MenuItem value={'12 months'}>12 months</MenuItem>
                <MenuItem value={'24 months'}>24 months</MenuItem>
              </LayoutSelect>
            </div>
          </LayoutContainer>
        ) : Type === 'advancedInputField' ? (
          // ************************* Professional Services Fields
          <AdvancedInputField {...basicProps} />
        ) : Type === 'surestInputField' ? (
          // ************************* Professional Services Fields SUREST
          <SurestInputField {...basicProps} updateProperty={updateProperty} />
        ) : Type === 'networkType' ? (
          // ************************* Professional Services Fields
          <NetworkTypes {...basicProps} />
        ) : Type === 'cost' ? (
          // ************************* Premiums & Contributions
          // !product?.Details?.PlanType?.startsWith("aca") ?
          <Cost {...basicProps} />
        ) : Type === 'carrier' ? (
          <Div
            css={css`
              width: calc(100% - 40px);
            `}
          >
            <Carriers {...basicProps} />
          </Div>
        ) : null}
      </Div>
      <Flag flag={flag} />
    </>
  );
};
